import { memo, useEffect, useRef, useState } from "react"
import 'bootstrap/dist/css/bootstrap.css';
import "./Fixservices.css"
import { Link } from "react-router-dom"
import servicelist from "../Servicelist/Servicelist"

function Fixservice(){
    const [index, setIndex]=useState(0)
    const [two, setTwo]=useState(2)
    const [number, setNumber]=useState(0)
    const myref=useRef()
    useEffect(()=>{
        console.log(myref.current);

        function first(){
            setIndex(index+1)
            setTwo(2);
            const ffirst=setTimeout(()=>{
            },2000)
            return (()=>{
                clearTimeout(ffirst)
            })
        }
        function second(){
            setTwo(0);
            setIndex(0);
        }
        const timer=setInterval(()=>{
            async function iamgod(){
                if(window.innerWidth>1200){
                    setNumber(33.3333)
                }else if(window.innerWidth>992){
                    setNumber(50)
                }else if(window.innerWidth>768){
                    setNumber(100)
                }else{
                    setNumber(100)
                }
                first();
                if(index>4){
                    second()
                }
            }
            iamgod()
        },3000)
        return (()=>{
            clearInterval(timer)
        })
    })
    const changer={
        transform:`translateX(-${index*number}%)`,
        transition:`${two}s`,
    }
    return (<>
    <div className="backerfixs mt-3 py-0">
        <div className="container py-1">
            {/* <div className="row fixrowser">
                <h6>TREATMENTS</h6>
                <h4>The facilities at spectra scans and diagnostics.</h4>
            </div>
            <div className="row">
            <div className="col-xl-9 col-lg-8 col-sm-6 col-12">
                <div className="rowhomehi">
                <div style={changer} className="rowhome">
                    {
                        servicelist.map((elem)=>{
                            const {img, name, link}=elem
                            {
                                return (
                                    <Link ref={myref} className="col-xl-4 col-lg-6 col-12 moniter p-1">
                    <div className="movingser pb-3">
                    <div className="w-100 serviceimg mb-4">
                        <img src={img} alt="" />
                    </div>
                    <h6>{name}</h6>
                    <h6>SPECTRA SCANS AND DIAGNOSTICS</h6>
                    </div>
                    </Link>
                                )
                            }
                        })
                    }
                    {
                        servicelist.map((elem)=>{
                            const {img, name, link}=elem
                            {
                                return (
                                    <Link className="col-xl-4 col-lg-6 col-12 moniter p-1">
                    <div className="movingser pb-3">
                    <div className="w-100 serviceimg mb-4">
                        <img src={img} alt="" />
                    </div>
                    <h6>{name}</h6>
                    <h6>ZAIN PILES HOSPITAL</h6>
                    </div>
                    </Link>
                                )
                            }
                        })
                    }
                </div>
            </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6 col-12 innertexth">
                <h6>We prioritize patient comfort and convenience, striving to create a warm and welcoming environment for all individuals. Our staff is dedicated to providing personalized care, addressing any concerns or questions you may have throughout the diagnostic process.</h6>
                <a className="contactfix" href=""><h6>Contact Us</h6></a>
            </div>
            </div> */}
            <div className="row">
            <div className="col-lg-6 col-12 filtersc jhvhjmn">
                        <from className="servers">  
                            <input className="inputerssr" type="text" placeholder="   Name"/>
                            <input className="inputerssr" type="text"  placeholder="   Phone"/>
                            <textarea className="inputersssr" type="text"  placeholder="   Message"/>
                            <div className="subber">
                                <button className="submitersr">Submit</button>
                            </div>
                        </from>
                    </div>
             <div className="col-lg-6 col-12 allnini">
                <div className="titless">
                    <div>
                        
                    <h1 style={{fontSize:"28px",lineHeight:"36px"}}>We have a dedicated and well experienced lady radiologist to do your scanning.</h1>
                                <a style={{textDecoration:"none"}} href="tel:081231 96290" className="submiter">Call Us</a>
                    </div>
                </div>
             </div>
            </div>
        </div>
    </div>
    </>)
}
export default memo(Fixservice)