import { memo, useEffect } from "react"
import Aboutusbanner from "./Aboutusbanner"
import Homeinfo from "../Homeinfo/Homeinfo"
import Useofscan from "../Useofscan/Useofscan"

function Aboutus(){
    useEffect(()=>{
        window.scrollTo({top:"0px",behavior:"smooth"})
    },[])
    return (<>
    <Aboutusbanner/>
    <Homeinfo/>
    <Useofscan/>
    </>)
}
export default memo(Aboutus)