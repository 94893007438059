import { memo, useEffect } from "react"
import Contactbanner from "./Contactbanner"
import Whychooseus from "../Whychooseus/Whychooseus"
import Services from "../Services/Services"
import Map from "../Whychooseus/Map"

function Contact(){
    useEffect(()=>{
        window.scrollTo({top:"0px",behavior:"smooth"})
    },[])
    return (<>
    <Contactbanner/>
    <Whychooseus/>
    <Map/>
    </>)
}
export default memo(Contact)