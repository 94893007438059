import { memo } from "react"

const servicelist=[
    {
        name:"Laboratory Testing",
        icon:"photo/aaa1.jpg",
        img:"photo/firstimg.jpg",
        containone:"We provide an extensive array of laboratory tests, including blood tests, urine analysis, genetic testing, and pathology services. Our advanced laboratory facilities are equipped with the latest instrumentation, ensuring reliable and efficient testing.",
        link:"",
    },
    {
        name:"Imaging Services",
        icon:"photo/aaa2.jpg",
        img:"photo/imagesecond.jpg",
        containone:"Our diagnostics center offers a comprehensive range of imaging services, such as X-rays, ultrasounds, and mammograms. These imaging techniques aid in the diagnosis and monitoring of various medical conditions.",
        link:"",
    },
    {
        name:"Ultrasound scanning",
        icon:"photo/aaa3.jpg",
        img:"photo/ultrasounds.jpg",
        containone:"Ultrasound scanning, also known as sonography, is a non-invasive medical imaging technique that has revolutionized the field of diagnostics. By harnessing sound waves, this versatile imaging modality allows healthcare professionals to visualize and evaluate the internal structures of the body in real-time.",
        link:"",
    },
    {
        name:"Fertility Test",
        icon:"photo/aaaa4.jpg",
        img:"photo/gastrointestinal.jpg",
        containone:"A fertility test is a medical evaluation conducted to assess an individual or a couple's reproductive health and their ability to conceive a child. These tests are performed if there are concerns or difficulties related to conceiving a baby. Both men and women can undergo fertility testing, as infertility issues can arise from either partner or a combination of factors.",
        link:"",
    },
    {
        name:"3D ultrasound",
        icon:"photo/aaaa5.jpg",
        img:"photo/3d4dscan.jpg",
        containone:"3D ultrasound is an advanced imaging technique that goes beyond traditional 2D ultrasound, providing a more detailed and realistic view of the fetus or targeted area of interest. By capturing multiple two-dimensional images from different angles.",
        link:"",
    },
    {
        name:"Wellness Screenings",
        icon:"photo/ww1.jpg",
        img:"photo/dtrhhgvhg.jpg",
        containone:"Wellness screenings are a proactive approach to healthcare that involves a series of tests and assessments aimed at detecting potential health risks and promoting overall well-being. These screenings are designed to identify early signs of diseases or conditions.",
        link:"",
    },
]
export default servicelist;