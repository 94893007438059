import { memo, useEffect } from "react"
import LaboratoryTestingbann from "./LaboratoryTestingbann"
import { Outlet } from "react-router-dom"

function LaboratoryTesting(){
    useEffect(()=>{
        window.scrollTo({top:"0px",behavior:"smooth"})
    },[])
    return (<>
    <Outlet/>
    </>)
}
export default memo(LaboratoryTesting)