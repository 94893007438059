import React from 'react'
import LazyLoad from 'react-lazy-load'
import { Link } from 'react-router-dom'

function Sonomammographybanner() {
  return (<>
    <LazyLoad>
        <div style={{height:"350px"}} className="banner sonomammography" >
            <div style={{textAlign:"center",justifyContent:"center"}}>
                <h3 style={{display:"flex",textAlign:"center",justifyContent:"center"}} className="mainbannertext">
Sonomammography</h3>
                <div className="bannertextf">
                    <Link to="/" className="bannertextf"><h6> Home </h6></Link>
                    <h6> / </h6>
                    <h6>
Sonomammography</h6>
                </div>
            </div>
        </div>
        </LazyLoad>
  </>
  )
}

export default Sonomammographybanner
