import { memo, useEffect } from "react"
import ImagingServicesbann from "./ImagingServicesbann"
import ImagingServicess from "./ImagingServicess"

function ImagingServices(){
    useEffect(()=>{
        window.scrollTo({top:"0px",behavior:"smooth"})
    },[])
    return (<>
    <ImagingServicesbann/>
    <ImagingServicess/>
    </>)
}
export default memo(ImagingServices)