import { memo } from "react"
import 'bootstrap/dist/css/bootstrap.css';
import "./Services.css"
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import servicelist from "../Servicelist/Servicelist";

function Servicess({name,icon,containone,backcolors,imginser}){
    let classer=["p-3"]
    classer.push(backcolors)
    const imging=[""]
    imging.push(imginser)
    return (<>
    <div className={classer.join(" ")}>
                    <div className={imging.join(" ")}>
                        <LazyLoadImage src={icon} alt="" />
                        <h6>{name}</h6>
                    </div>
                    <div className="imgslinks">
                        <p>{containone}</p>
                    </div>
                    </div>
    </>)
}
function Services(){
    return (<>
    <div className="mbottommer">
        <div className="container">
            <div className="row">
                <div className="col-xl-4 lattersl">
                    <h1>Spectra Scans and Diagnostic</h1>
                </div>
                <div className="col-xl-8 taier">
                    <p>Your trust is invaluable to us. That's why we strive for transparency and open communication, ensuring you have a thorough understanding of your diagnosis, empowering you to actively participate in your healthcare decisions and achieve the best possible outcomes</p>
                </div>
            </div>
            <div className="row pt-3">
                
    {
                    servicelist.map((elem, num)=>
                    {
                        console.log(num);
                        const {icon, name,containone}=elem
                        return (<>
                        <div className="col-lg-4 col-md-6 col-12 mt-4">{
                            num===0?
                            <Servicess icon={icon} name={name} containone={containone} backcolors="backcolors" imginser="imginser"/>
                            :
                            <Servicess icon={icon} name={name} containone={containone} backcolors="backcolorser" imginser="imginsers"/>
                        }
                </div>
                        </>)
                    })
                }
            </div>
        </div>
    </div>
    </>)
}
export default memo(Services)