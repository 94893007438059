import { memo, useEffect } from "react"
import LazyLoad from "react-lazy-load"
import LaboratoryTestingbann from "./LaboratoryTestingbann"

function LaboratoryTestings(){
    useEffect(()=>{
        window.scrollTo({top:"0px",behavior:"smooth"})
    },[])
    return (<>
    <LaboratoryTestingbann/>
     <section className="mt-3">
            <div className="container">
                <div className="row">
                    <h2>Ultrasound scans</h2>
                    <p>Ultrasound scans, also known as sonography or ultrasound imaging, are a non-invasive medical imaging technique used to visualize internal structures and organs in the body. Ultrasound technology utilizes high-frequency sound waves to create real-time images of various body parts, allowing healthcare professionals to assess and diagnose medical conditions.</p>
                </div>
            </div>
        </section>
    <section className="mt-3 py-5 backgrey">
        <div className="container">
            <div className="centers">
                <h5 style={{lineHeight:"28px"}}>Ultrasound Scans</h5>
            </div>
            <div className="row">
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-12 mb-2">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../photo/bbb1.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-12 linetext">
                            <h6>Antenatal/pregnancy scans</h6>
                            <p>Antenatal are a series of medical imaging procedures performed during pregnancy to monitor the development of the fetus.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-12 mb-2">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../photo/bbb2.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-12 linetext">
                            <h6>Abdomen pelvic scans</h6>
                            <p>An abdomen and pelvic scan is a medical imaging procedure used to visualize and evaluate the organs and structures within the abdominal and pelvic regions.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-12 mb-2">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../photo/bbb3.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-12 linetext">
                            <h6>Color doppler</h6>
                            <p>Color Doppler is a specialized ultrasound imaging technique that combines traditional B-mode ultrasound with color-coded Doppler information to visualize and assess blood flow within the body's blood vessels.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-12  mb-2">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../photo/bbb4.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-12 linetext">
                            <h6>Fertility scans</h6>
                            <p>Fertility scans, also known as fertility ultrasounds or reproductive ultrasound examinations, are a type of medical imaging used to assess and monitor the reproductive organs in both men and women.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>)
}
export default memo(LaboratoryTestings)