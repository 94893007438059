import { memo, useEffect } from "react"
import Header from "../Header/Header"
import Clinicinfo from "../Clinicinfo/Clinicinfo"
import Homeinfo from "../Homeinfo/Homeinfo"
import Services from "../Services/Services"
import Fixservices from "../Fixedservices/Fixservices"
import Packages from "../Packages/Packages"
import Backfirstfix from "../Backfirstfix/Backfirstfix"
import Footer from "../Footer/footer"
import Contactbox from "../Contactbox/Contactbox"
import Review from "../Review/Review"
import Slider from "../Slider/Slider"
import Useofscan from "../Useofscan/Useofscan"
import Whychooseus from "../Whychooseus/Whychooseus"
import Aboutusbanner from "../AboutUs/Aboutusbanner"
import Mobbar from "../Mobbar/Mobbar"

function Home(){
    useEffect(()=>{
        window.scrollTo({top:"0px",behavior:"smooth"})
    },[])
    return (<>
    <Slider/>
    <Homeinfo/>
    {/* <Contactbox/> */}
    <Clinicinfo/>
    <Services/>
    <Fixservices/>
    {/* <Packages/> */}
    {/* <Review/> */}
    {/* <Backfirstfix/> */}
    <Useofscan/>
    {/* <Whychooseus/> */}
    <Review/>
    </>)
}
export default memo(Home)