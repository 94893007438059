import { memo } from "react"
import 'bootstrap/dist/css/bootstrap.css';
import "./Header.css"
import { Link } from "react-router-dom"

function Header({setNamcon}){
    return (<>
    <header className="headertop">
        <div className="outerfirst">
            <div className="container">
                <div className="headersec">
                    <div className="homecall textimg firstphone pt-1 pb-1">
                        <img src="../photo/telephone.png" alt="" />
                        <h6>081231 96290</h6>
                        <div className="imginlogoe">
                        <img src="../photo/email.png" alt="" />
                        <h6>spectrascans@gmail.com</h6>
                        </div>
                    </div>
                    <div className="display-flex">
                        <a className="homecall textimg paddimg firsticon" href="">  
                            <img src="../photo/facebook-logo.png" alt="" />
                        </a>
                        <a className="homecall textimg paddimg firsticon" href="">  
                            <img src="../photo/google.png" alt="" />
                        </a>
                        <a className="homecall textimg paddimg firsticon" href="">  
                            <img src="../photo/twitter.png" alt="" />
                        </a>
                        <a className="homecall textimg paddimg firsticon" href="">  
                            <img src="../photo/instagram.png" alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="headersec">
                <Link to='/' className="logobox">
                    <img src="../photo/logo.png" alt="" />
                </Link>
                <div className="linkbox">
                    <ul className="linkbox">
                        <li><Link to="/" className="headerlink">Home</Link></li>
                        <li><Link to="/about" className="headerlink">About</Link></li>
                        <li className="viner"><Link className="headerlink">Services</Link>
                        <ul className="uliheder">
                        <li className="kite">
                            <Link to="/service/laboratoryTesting" className="linker"><h6  className="linkera">Ultrasound scans </h6></Link>
                        </li>
                        <li className="kite">
                            <Link to="/service/imagingServices" className="linker"><h6  className="linkera">Digital x rays</h6></Link>
                        </li>
                        <li className="kite">
                            <Link to="/service/wellnessScreenings" className="linker"><h6  className="linkera">Hysterosalpingography</h6></Link>
                        </li>
                        <li className="kite">
                            <Link to="/service/laborator" className="linker"><h6  className="linkera">Laboratory/Blood tests</h6></Link>
                        </li>
                        <li className="kite">
                            <Link to="/service/sonomammography" className="linker"><h6  className="linkera">Sonomammography</h6></Link>
                        </li>
                    </ul>
                        </li>
                        <li>
                    <Link to="/contact" className="headerlink">Contact</Link></li>
                    </ul>
                    
                    
                    
                    
                    {/* <Link className="headerlink">Our Doctors</Link>
                    <Link className="headerlink">Gallery</Link> */}
                </div>
                <div className="linkappiontd">
                    <Link className="linkappiont">Appointment</Link>
                </div>
                <button className="menuouter">
                    <div onClick={()=>{
                        setNamcon("block")
                    }} className="menu">
                        <img src="../photo/list.png" alt="" />
                    </div>
                </button>
            </div>
        </div>
    </header>
    </>)
}
export default memo(Header)