import { memo } from "react"
import LazyLoad from "react-lazy-load"

function WellnessScreeningsss(){
    return (<>
        <section className="mt-3">
               <div className="container">
                   <div className="row">
                       <h2>Hysterosalpingography</h2>
                       <p>Hysterosalpingography (HSG) is a diagnostic medical imaging procedure used to evaluate the patency of the fallopian tubes and the shape of the uterine cavity in women. It is commonly performed as part of the infertility evaluation process or to investigate potential causes of abnormal uterine bleeding.</p>
                       
                   </div>
               </div>
           </section>
       <section className="mt-3 py-5 backgrey">
           <div className="container">
               <div className="centers">
                   <h5 style={{lineHeight:"28px"}}>Hysterosalpingography (HSG) can be beneficial to patients in several ways, particularly for women who are experiencing fertility issues or abnormal uterine bleeding. Here are some of the ways in which HSG can help patients.</h5>
               </div>
               <div className="row">
                   <div className="col-xl-4 col-md-6 p-222">
                       <div className="row hiders p-1">
                           <div className="col-4 higerli">
                               <LazyLoad className="w-100">
                                   <img className="w-100" src="../photo/cc1.jpg" alt="" />
                               </LazyLoad>
                           </div>
                           <div className="col-8 p-1 linetext">
                               <h6>Evaluating Fallopian Tube Patency</h6>
                               <p>One of the primary reasons for performing HSG is to assess the patency (openness) of the fallopian tubes.</p>
                           </div>
                       </div>
                   </div>
                   <div className="col-xl-4 col-md-6 p-222">
                       <div className="row hiders p-1">
                           <div className="col-4 higerli">
                               <LazyLoad className="w-100">
                                   <img className="w-100" src="../photo/cc2.jpg" alt="" />
                               </LazyLoad>
                           </div>
                           <div className="col-8 p-1 linetext">
                               <h6>Identifying Uterine Abnormalities</h6>
                               <p>HSG provides valuable information about the shape and structure of the uterine cavity.</p>
                           </div>
                       </div>
                   </div>
                   <div className="col-xl-4 col-md-6 p-222">
                       <div className="row hiders p-1">
                           <div className="col-4 higerli">
                               <LazyLoad className="w-100">
                                   <img className="w-100" src="../photo/cc3.jpg" alt="" />
                               </LazyLoad>
                           </div>
                           <div className="col-8 p-1 linetext">
                               <h6>Guiding Fertility Treatment</h6>
                               <p>For couples undergoing fertility treatments such as in vitro fertilization, HSG results can help healthcare providers.</p>
                           </div>
                       </div>
                   </div>
                   <div className="col-xl-4 col-md-6 p-222">
                       <div className="row hiders p-1">
                           <div className="col-4 higerli">
                               <LazyLoad className="w-100">
                                   <img className="w-100" src="../photo/cc4.jpg" alt="" />
                               </LazyLoad>
                           </div>
                           <div className="col-8 p-1 linetext">
                               <h6>Offering Diagnostic Clarity</h6>
                               <p>HSG can provide clarity when other imaging methods are inconclusive in identifying certain uterine or tubal conditions.</p>
                           </div>
                       </div>
                   </div>
                   <div className="col-xl-4 col-md-6 p-222">
                       <div className="row hiders p-1">
                           <div className="col-4 higerli">
                               <LazyLoad className="w-100">
                                   <img className="w-100" src="../photo/cc5.jpg" alt="" />
                               </LazyLoad>
                           </div>
                           <div className="col-8 p-1 linetext">
                               <h6>Emotional Support</h6>
                               <p>For couples experiencing fertility challenges, HSG can offer insights into potential factors affecting conception.</p>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </section>
    </>)
}
export default memo(WellnessScreeningsss)