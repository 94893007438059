import { Link } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import Carousel from 'react-bootstrap/Carousel';
import { memo, useState } from "react";

function Slider() {
    return (
    <Carousel>
    <Carousel.Item>
    <div className="biggy">
      <img
        className="d-block w-100"
        src="../photo/highone.jpg"
        alt="First slide"
      />
      </div>
      <div className="smaller">
      <img
        className="d-block w-100"
        src="../photo/smallerone.jpg"
        alt="First slide"
      />
      </div>
    </Carousel.Item>
    <Carousel.Item>
    <div className="biggy">
      <img
        className="d-block w-100"
        src="photo/llllb.jpg"
        alt="Second slide"
      />
      </div>
      <div className="smaller">
      <img
        className="d-block w-100"
        src="../photo/lllls.jpg"
        alt="First slide"
      />
      </div>
    </Carousel.Item>
    <Carousel.Item>
    <div className="biggy">
      <img
        className="d-block w-100"
        src="photo/secondthreeb.jpg"
        alt="Second slide"
      />
      </div>
      <div className="smaller">
      <img
        className="d-block w-100"
        src="../photo/secondthrees.jpg"
        alt="First slide"
      />
      </div>
    </Carousel.Item>
    <Carousel.Item>
    <div className="biggy">
      <img
        className="d-block w-100"
        src="photo/seondfourb.jpg"
        alt="Second slide"
      />
      </div>
      <div className="smaller">
      <img
        className="d-block w-100"
        src="../photo/secondfours.jpg"
        alt="First slide"
      />
      </div>
    </Carousel.Item>
  </Carousel>
);
}

export default memo(Slider);