import { memo, useEffect, useRef } from "react"
import 'bootstrap/dist/css/bootstrap.css';
import "./Clinicinfo.css"
import { LazyLoadImage } from "react-lazy-load-image-component"
function Addressinfo(){
    const myref=useRef()
    useEffect(()=>{
    // console.log(myref.current.getBoundingClientRect().top);

    })
    return (<>
    <section className="adddbox mt-5 mb-5">
        <div className="container">
            <div className="row">
                <div ref={myref} className="col-lg-3 col-md-6 addinfobox addinfohover p-2">
                    <div className="innerinfobox  backred p-3">
                        <div className="textboxin">
                        <div className="addresicon">
                            <LazyLoadImage src="photo/mm1.jpg" alt="" />
                        </div>
                        <div className="timeinfo">
                            <h6>Early detection of health risks associated with erratic and stressful lifestyle</h6>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 addinfobox addinfohover p-2">
                    <div className="innerinfobox  backred p-3">
                        <div className="textboxin">
                        <div className="addresicon">
                            <LazyLoadImage src="photo/xx2.jpg" alt="" />
                        </div>
                        <div className="timeinfo">
                            <h6>Identify high risks associated with hereditary diseases</h6>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 addinfobox addinfohover p-2">
                    <div className="innerinfobox  backred p-3">
                        <div className="textboxin">
                        <div className="addresicon">
                            <LazyLoadImage src="photo/xx3.jpg" alt="" />
                        </div>
                        <div className="timeinfo">
                            <h6>Detect co-existing diseases early</h6>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 addinfobox addinfohover p-2">
                    <div className="innerinfobox  backred p-3">
                        <div className="textboxin">
                        <div className="addresicon">
                            <LazyLoadImage src="photo/xx4.jpg" alt="" />
                        </div>
                        <div className="timeinfo">
                            <h6>Monitor the impact of medication & lifestyle modifications</h6>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>)
}
export default memo(Addressinfo)