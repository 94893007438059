import { memo, useEffect } from "react"
import WellnessScreeningsbann from "./WellnessScreeningsbann"
import WellnessScreeningsss from "./WellnessScreeningsss"

function WellnessScreenings(){
    useEffect(()=>{
        window.scrollTo({top:"0px",behavior:"smooth"})
    },[])
    return (<>
    <WellnessScreeningsbann/>
    <WellnessScreeningsss/>
    </>)
}
export default memo(WellnessScreenings)