import { memo } from "react"
import LazyLoad from "react-lazy-load"
import { Link } from "react-router-dom"
import "./About.css"

function Aboutusbanner(){
    return (<>
        <LazyLoad>
            <div style={{height:"350px"}} className="banner aboutbanner" >
                <div style={{textAlign:"center",justifyContent:"center"}}>
                    <h3 style={{display:"flex",textAlign:"center",justifyContent:"center"}} className="mainbannertext"> About Us </h3>
                    <div className="bannertextf">
                        <Link to="/" className="bannertextf"><h6> Home </h6></Link>
                        <h6> / </h6>
                        <h6> About Us </h6>
                    </div>
                </div>
            </div>
            </LazyLoad>
    </>)
}
export default memo(Aboutusbanner)