import { memo, useEffect, useState } from "react"
import { Link } from "react-router-dom"

function Fixheader({setNamcon}) {
    const [dis, setDis]=useState("none")
    useEffect(()=>{
        window.addEventListener("scroll",()=>{
            if(window.scrollY>200){
                setDis("block")
            }else{
                setDis("none")
            }
        })
    })
    const chan={
        display:`${dis}`
    }
    return (<>
    <header style={chan} className="headertop fixheder">
    <div className="container">
        <div className="headersec">
            <Link to='/' className="logobox">
                <img src="../photo/logo.png" alt="" />
            </Link>
            <div className="linkbox">
                <ul className="linkbox">
                    <li><Link to="/" className="headerlink">Home</Link></li>
                    <li><Link to="/about" className="headerlink">About</Link></li>
                    <li className="viner"><Link className="headerlink">Services</Link>
                        <ul className="uliheder">
                        <li className="kite">
                            <Link to="/service/laboratoryTesting" className="linker"><h6  className="linkera">Ultrasound scans</h6></Link>
                        </li>
                        <li className="kite">
                            <Link to="/service/imagingServices" className="linker"><h6  className="linkera">Digital x rays</h6></Link>
                        </li>
                        <li className="kite">
                            <Link to="/service/wellnessScreenings" className="linker"><h6  className="linkera">Hysterosalpingography</h6></Link>
                        </li>
                        <li className="kite">
                            <Link to="/service/laborator" className="linker"><h6  className="linkera">Laboratory/Blood tests</h6></Link>
                        </li>
                        <li className="kite">
                            <Link to="/service/sonomammography" className="linker"><h6  className="linkera">Sonomammography</h6></Link>
                        </li>
                    </ul>
                    </li>
                    <li>
                <Link to="/contact" className="headerlink">Contact</Link></li>
                </ul>
                
                
                
                
                {/* <Link className="headerlink">Our Doctors</Link>
                <Link className="headerlink">Gallery</Link> */}
            </div>
            <div className="linkappiontd">
                <Link className="linkappiont">Appointment</Link>
            </div>
            <button className="menuouter">
                <div onClick={()=>{
                    setNamcon("block")
                }} className="menu">
                    <img src="../photo/list.png" alt="" />
                </div>
            </button>
        </div>
    </div>
</header>
    </>)
}
export default memo(Fixheader)