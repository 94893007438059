import { memo, useEffect, useRef, useState } from "react"
import LazyLoad from "react-lazy-load"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./Review.css"
import { LazyLoadImage } from "react-lazy-load-image-component"

function Review(){
    let menu=[
        {
            name:"dhartiraj bhaya rathod",
            img:"photo/nn1.jpg",
            star:[
                {
                    id:"photo/star.png"
                },
                {
                    id:"photo/star.png"
                },
                {
                    id:"photo/star.png"
                },
                {
                    id:"photo/star.png"
                },
                {
                    id:"photo/star.png"
                },
            ],
            content:"Found spectra scan center to be the best in haralur area. The staff is very friendly and the doctors were patient to explain, emphasize and knowledgeable in their areas. Thank you."
        },
        {
            name:"Sree Harsha Sure",
            img:"photo/nn2.jpg",
            star:[
                {
                    id:"photo/star.png"
                },
                {
                    id:"photo/star.png"
                },
                {
                    id:"photo/star.png"
                },
                {
                    id:"photo/star.png"
                },
                {
                    id:"photo/star.png"
                },
            ],
            content:"Best service for blood test and scans Dr shradha was very friendly and helpful during my scan. Overall good experience for all scans. I recommend spectra scan. Thank you"
        },
        {
            name:"Jaspreet Singh",
            img:"photo/nn3.jpg",
            star:[
                {
                    id:"photo/star.png"
                },
                {
                    id:"photo/star.png"
                },
                {
                    id:"photo/star.png"
                },
                {
                    id:"photo/star.png"
                },
                {
                    id:"photo/star.png"
                },
            ],
            content:"Dr Shraddha Sethi is very proficient in her job and very polite and helpful. No wait time .. Very happy with the service.. thanks alot"
        },
        {
            name:"Rajendra Poojari",
            img:"photo/nn4.jpg",
            star:[
                {
                    id:"photo/star.png"
                },
                {
                    id:"photo/star.png"
                },
                {
                    id:"photo/star.png"
                },
                {
                    id:"photo/star.png"
                },
                {
                    id:"photo/star.png"
                },
            ],
            content:"Appreciate the best quality of service that I’ve received from Spectra scan and Diagnostics Center Their competence, caring nature, sincerity, valuing Patient’s time – all these together have shaped them as the most trusted diagnostic service provider Keep it up the good work!"
        },
        {
            name:"MA PHARMA",
            img:"photo/nn5.jpg",
            star:[
                {
                    id:"photo/star.png"
                },
                {
                    id:"photo/star.png"
                },
                {
                    id:"photo/star.png"
                },
                {
                    id:"photo/star.png"
                },
                {
                    id:"photo/star.png"
                },
            ],
            content:"Doctor is very professional,takes time to explain things ,resolve problems,highly grateful for her experience  and wide range of knowledge, best female radiologists  around the location, recommended to all."
        },
    ]


    const [index, setIndex]=useState(0)
    const [two, setTwo]=useState(2)
    const [number, setNumber]=useState(0)
    useEffect(()=>{
        function first(){
            setIndex(index+1)
            setTwo(2);
            const ffirst=setTimeout(()=>{
            },5000)
            return (()=>{
                clearTimeout(ffirst)
            })
        }
        function second(){
            setTwo(0);
            setIndex(0);
        }
        const timer=setInterval(()=>{
            async function iamgod(){
                if(window.innerWidth>1200){
                    setNumber(25)
                }else if(window.innerWidth>992){
                    setNumber(33.3333)
                }else if(window.innerWidth>768){
                    setNumber(50)
                }else{
                    setNumber(100)
                }
                first();
                if(index>menu.length){
                    second()
                }
            }
            iamgod()
        },8000)
        return (()=>{
            clearInterval(timer)
        })
    })
    const changer={
        transform:`translateX(-${index*number}%)`,
        transition:`${two}s`,
    }

    const [menuer, setMenuver]=useState(menu)
    const [name, setName]=useState("");
    const [firstmover, setFirstmover]=useState()
    const [stara, setStara]=useState("photo/star (1).png")
    const [starb, setStarb]=useState("photo/star (1).png")
    const [starc, setStarc]=useState("photo/star (1).png")
    const [stard, setStard]=useState("photo/star (1).png")
    const [stare, setStare]=useState("photo/star (1).png")
    const [message, setMasseage]=useState("");
    const [secondmover, setSeciondmover]=useState("")
    const [userimg, setImag]=useState("photo/user.png")
    // const myref=useRef()
    // const secref=useRef()
    function gateName(val){
        setName(val.target.value)
        setFirstmover(val)
    }
    function gateMessage(val){
        setMasseage(val.target.value)
        setSeciondmover(val)
    }
    const [numbers, setNumbers]=useState(1)
    function gateStar(){
        setNumbers(numbers+1)
        switch(numbers) {
            case 1:
                setStara("photo/star.png")
              break;
            case 2:
                setStarb("photo/star.png")
              break;
            case 3:
                setStarc("photo/star.png")
              break;
            case 4:
                setStard("photo/star.png")
            break;
            case 5:
                setStare("photo/star.png")
              break;
            default:
                setStara("photo/star (1).png")
                setStarb("photo/star (1).png")
                setStarc("photo/star (1).png")
                setStard("photo/star (1).png")
                setStare("photo/star (1).png")
                setNumbers(1)
          }
    }
    function submit(){
        const objecter={
            name:`${name}`,
            img:"photo/gt.jpg",
            star:[
                {
                    id:`${stara}`
                },
                {
                    id:`${starb}`
                },
                {
                    id:`${starc}`
                },
                {
                    id:`${stard}`
                },
                {
                    id:`${stare}`
                },
            ],
            content:`${message}`
        }
        menu.push(objecter)
        console.log(menu);
        alert("Your review is Submited")
        setMenuver(menu)
        try{
            firstmover.target.value=""
            secondmover.target.value=""
        }catch{

        }
        setStara("photo/star (1).png")
        setStarb("photo/star (1).png")
        setStarc("photo/star (1).png")
        setStard("photo/star (1).png")
        setStare("photo/star (1).png")
        localStorage.setItem("menu",JSON.stringify(menu))
    }
    localStorage.setItem("menu",JSON.stringify(menu))
    useEffect(()=>{
        try{
            let oner=(localStorage.getItem("menu"));
            setMenuver(JSON.parse(oner))
        }catch{
            setMenuver(menu)
        }
    },[])
    // function imgeselec(){
    //     setImag(myref.current.files[0])
    //     secref.current.src=URL.createObjectURL(myref.current.files[0])
    // }
    return (<>
         <div>
            <div className="container mt-5 mb-5">
                <div className="row">
                    {/* <div style={{position:"relative"}} className="col-xl-7 col-lg-6 mb-2">
                        <LazyLoad className="reviewimg">
                            <img src="photo/imaerer.jpg" alt="" />
                        </LazyLoad>
                        <div className="col-xl-8 col-sm-10 reviewinter">
                        <div className="col-12 reviewbox">
                             <div>
                        <div className="col-12 centerreviewt">
                            <h5>Review Box</h5>
                        </div>
                                    <div className="col-12 frfeviewuser">
                                        <LazyLoadImage for="input-file" className="ussersph" src="photo/user.png"></LazyLoadImage>
                                    </div>
                                <div className="mainreview">
                                    <div className="col-12">
                                    <div className="col-12 ">
                                    <ul className="startbox centerul col-12er" onClick={gateStar}>
                                                <li>
                                                    <LazyLoadImage className="star" src={stara}></LazyLoadImage>
                                                </li>
                                                <li>
                                                    <LazyLoadImage className="star" src={starb}></LazyLoadImage>
                                                </li>
                                                <li>
                                                    <LazyLoadImage className="star" src={starc}></LazyLoadImage>
                                                </li>
                                                <li>
                                                    <LazyLoadImage className="star" src={stard}></LazyLoadImage>
                                                </li>
                                                <li>
                                                    <LazyLoadImage className="star" src={stare}></LazyLoadImage>
                                                </li>
                                        </ul>
                                        <input type="text" className="textinre" placeholder="    Enter Your Name" onChange={gateName}></input>
                                    </div>
                                    </div>
                                </div>
                                <textarea className="textarere" placeholder="    Message" onChange={gateMessage}></textarea>
                                <div className="col-12 submiterdiv">
                                    <button className="submiter"><h6>Cancel</h6></button>
                                    <button className="submiter" onClick={submit}><h6>Submit</h6></button>
                                </div>
                             </div>
                    </div>
                        </div>
                    </div> */}
                    <div className="col-xl-12 col-lg-12">
                        <div className="col-12 innfoinrev mb-2">
                            <h5 style={{fontSize:"36px",marginBottom:"10px", color:"#0093c9"}}>Why choose us</h5>
                            <p>We prioritize patient comfort and convenience, striving to create a warm and welcoming environment for all individuals. Our staff is dedicated to providing personalized care, addressing any concerns or questions you may have throughout the diagnostic process.</p>
                            <p>As a diagnostics center, we maintain strict adherence to ethical standards, patient confidentiality, and regulatory guidelines. Your privacy and data security are of utmost importance to us.
                            We look forward to serving you and assisting you in your healthcare journey. Our goal is to provide accurate diagnoses, facilitate effective treatments, and ultimately contribute to your overall well-being.</p>
                        </div>
                        <div className="col-12 centerdis mt-2">
                            <div className="col-sm-12 col-12">
                                <div className="col-12 textcenter innfoinrev mb-5">  
                                    <h5 style={{color:"#0093c9"}}>What Patients Says?</h5>
                                </div>
                                <div className="col-12 mt-2">
                                <div className="rowhomehi">
                        <div style={changer} className="rowhome">
                            {
                        menuer.map((elem)=>{
                        const {name, img, star, content}=elem
                        return (<>
                        <div className="col-xl-3 col-lg-4 col-12 p-2 reviewbox">
                             <div>
                                <div className="mainreview">
                                    <div>
                                        <LazyLoadImage className="usser" src={img}></LazyLoadImage>
                                    </div>
                                    <div className="centerre">
                                    <div className="conre">
                                        <h6>{name}</h6>
                                        <ul className="startbox">{
                                            star.map((num)=>{
                                                const {id}=num
                                                return (<>
                                                <li>
                                                    <LazyLoadImage className="star" src={id}></LazyLoadImage>
                                                </li>
                                                </>)
                                            })
                                        }
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                                <p className="reviewp mt-1">
                                {content}
                                </p>
                             </div>
                    </div>
                        </>)
                    })
                }
                            {
                        menuer.map((elem)=>{
                        const {name, img, star, content}=elem
                        return (<>
                        <div className="col-xl-3 col-lg-4 col-12 p-2 reviewbox">
                             <div>
                                <div className="mainreview">
                                    <div>
                                        <LazyLoadImage className="usser" src={img}></LazyLoadImage>
                                    </div>
                                    <div className="centerre">
                                    <div className="conre">
                                        <h6>{name}</h6>
                                        <ul className="startbox">{
                                            star.map((num)=>{
                                                const {id}=num
                                                return (<>
                                                <li>
                                                    <LazyLoadImage className="star" src={id}></LazyLoadImage>
                                                </li>
                                                </>)
                                            })
                                        }
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                                <p className="reviewp mt-1">
                                {content}
                                </p>
                             </div>
                    </div>
                        </>)
                    })
                }
                        </div>
                        </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </div>
    </>)
}
export default memo(Review)