import React from 'react'
import LazyLoad from 'react-lazy-load'

function Laboratoryser() {
  return (<>
    <section className="mt-3">
           <div className="container">
               <div className="row">
                   <h2>Laboratory/Blood tests</h2>
                   <p>Laboratory and blood tests are important diagnostic tools used in the field of medicine to assess a person's health, diagnose medical conditions, and monitor the effectiveness of treatments. These tests provide valuable information about various aspects of a person's health, including blood cell counts, organ function, hormone levels, and more.</p>
                   
               </div>
           </div>
       </section>
   <section className="mt-3 py-5 backgrey">
       <div className="container">
           <div className="centers">
               <h5 style={{lineHeight:"28px"}}>Here are some common laboratory and blood tests.</h5>
           </div>
           <div className="row">
               <div className="col-xl-4 col-md-6 p-222">
                   <div className="row hiders p-1">
                       <div className="col-4 higerli">
                           <LazyLoad className="w-100">
                               <img className="w-100" src="../photo/rr1.jpg" alt="" />
                           </LazyLoad>
                       </div>
                       <div className="col-8 p-1 linetext">
                           <h6>Complete Blood Count (CBC)</h6>
                           <p>This test measures the levels of different blood cells, including red blood cells, white blood cells, and platelets.</p>
                       </div>
                   </div>
               </div>
               <div className="col-xl-4 col-md-6 p-222">
                   <div className="row hiders p-1">
                       <div className="col-4 higerli">
                           <LazyLoad className="w-100">
                               <img className="w-100" src="../photo/rr2.jpg" alt="" />
                           </LazyLoad>
                       </div>
                       <div className="col-8 p-1 linetext">
                           <h6>Basic Metabolic Panel (BMP)</h6>
                           <p>This test evaluates various electrolytes, glucose, and kidney function markers to assess the body's overall metabolic status.</p>
                       </div>
                   </div>
               </div>
               <div className="col-xl-4 col-md-6 p-222">
                   <div className="row hiders p-1">
                       <div className="col-4 higerli">
                           <LazyLoad className="w-100">
                               <img className="w-100" src="../photo/rr3.jpg" alt="" />
                           </LazyLoad>
                       </div>
                       <div className="col-8 p-1 linetext">
                           <h6>Comprehensive Metabolic Panel (CMP)</h6>
                           <p>Similar to BMP, this test includes additional liver function tests, such as bilirubin and liver enzymes.</p>
                       </div>
                   </div>
               </div>
               <div className="col-xl-4 col-md-6 p-222">
                   <div className="row hiders p-1">
                       <div className="col-4 higerli">
                           <LazyLoad className="w-100">
                               <img className="w-100" src="../photo/rr4.jpg" alt="" />
                           </LazyLoad>
                       </div>
                       <div className="col-8 p-1 linetext">
                           <h6>Lipid Profile</h6>
                           <p>This test measures cholesterol levels (total cholesterol, LDL cholesterol, HDL cholesterol) and triglycerides to assess cardiovascular health.</p>
                       </div>
                   </div>
               </div>
               <div className="col-xl-4 col-md-6 p-222">
                   <div className="row hiders p-1">
                       <div className="col-4 higerli">
                           <LazyLoad className="w-100">
                               <img className="w-100" src="../photo/rr5.jpg" alt="" />
                           </LazyLoad>
                       </div>
                       <div className="col-8 p-1 linetext">
                           <h6>Thyroid Function Tests</h6>
                           <p>These tests measure levels of thyroid hormones (TSH, T3, T4) to assess thyroid gland function and detect thyroid disorders.</p>
                       </div>
                   </div>
               </div>
               <div className="col-xl-4 col-md-6 p-222">
                   <div className="row hiders p-1">
                       <div className="col-4 higerli">
                           <LazyLoad className="w-100">
                               <img className="w-100" src="../photo/rr6.jpg" alt="" />
                           </LazyLoad>
                       </div>
                       <div className="col-8 p-1 linetext">
                           <h6>Blood Glucose Test</h6>
                           <p>Measures the level of glucose (sugar) in the blood to screen for diabetes or monitor its management.</p>
                       </div>
                   </div>
               </div>
           </div>
       </div>
   </section>
  </>
  )
}

export default Laboratoryser
