import React from 'react'
import LazyLoad from 'react-lazy-load'

function Sonomammographyser() {
  return (<>
    <section className="mt-3">
           <div className="container">
               <div className="row">
                   <h2>Sonomammography</h2>
                   <p>Sonomammography is a specialised scan of the breasts that help in early detection of breast diseases and breast cancer.</p>
                   <p>Sonomammography, also known as breast ultrasound or breast sonography, is a medical imaging technique used to examine the breast tissue using high-frequency sound waves. It is often used as a supplementary imaging method alongside mammography and other breast imaging techniques to provide more information about breast abnormalities.</p>
                   <p>Sonomammography works by emitting sound waves into the breast tissue and recording the echoes that bounce back. These echoes are then converted into images that can help visualize the internal structures of the breast. It is particularly useful for distinguishing between solid masses and fluid-filled cysts, as well as for evaluating breast lumps detected during self-examinations or clinical exams.</p>
                   
               </div>
           </div>
       </section>
   <section className="mt-3 py-5 backgrey">
       <div className="container">
           <div className="centers">
               <h5 style={{lineHeight:"28px"}}>Some common uses of sonomammography include.</h5>
           </div>
           <div className="row">
               <div className="col-xl-4 col-md-6 p-222">
                   <div className="row hiders p-1">
                       <div className="col-4 higerli">
                           <LazyLoad className="w-100">
                               <img className="w-100" src="../photo/ll1.jpg" alt="" />
                           </LazyLoad>
                       </div>
                       <div className="col-8 p-1 linetext">
                           <h6>Differentiating between cysts and solid masses</h6>
                           <p>Ultrasound can help determine whether a breast lump is a benign cyst or a solid mass.</p>
                       </div>
                   </div>
               </div>
               <div className="col-xl-4 col-md-6 p-222">
                   <div className="row hiders p-1">
                       <div className="col-4 higerli">
                           <LazyLoad className="w-100">
                               <img className="w-100" src="../photo/ll2.jpg" alt="" />
                           </LazyLoad>
                       </div>
                       <div className="col-8 p-1 linetext">
                           <h6>Guiding biopsies</h6>
                           <p>Ultrasound can help guide the placement of a biopsy needle for tissue sampling, particularly for suspicious areas that are difficult to visualize using other imaging methods.</p>
                       </div>
                   </div>
               </div>
               <div className="col-xl-4 col-md-6 p-222">
                   <div className="row hiders p-1">
                       <div className="col-4 higerli">
                           <LazyLoad className="w-100">
                               <img className="w-100" src="../photo/ll3.jpg" alt="" />
                           </LazyLoad>
                       </div>
                       <div className="col-8 p-1 linetext">
                           <h6>Evaluating dense breast tissue</h6>
                           <p> In women with dense breast tissue, mammography might not provide clear images.</p>
                       </div>
                   </div>
               </div>
               <div className="col-xl-4 col-md-6 p-222">
                   <div className="row hiders p-1">
                       <div className="col-4 higerli">
                           <LazyLoad className="w-100">
                               <img className="w-100" src="../photo/ll4.jpg" alt="" />
                           </LazyLoad>
                       </div>
                       <div className="col-8 p-1 linetext">
                           <h6>Monitoring breast health</h6>
                           <p>Sonomammography can be used for routine breast screenings, especially in cases where mammography might not be suitable, such as in younger women or pregnant women.</p>
                       </div>
                   </div>
               </div>
           </div>
       </div>
   </section>
  </>
  )
}

export default Sonomammographyser
