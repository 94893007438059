import React, { useEffect } from 'react'
import Laboratorybanner from './Laboratorybanner'
import Laboratoryser from './Laboratoryser'

function Laboratory() {
  useEffect(()=>{
    window.scrollTo({top:"0px",behavior:"smooth"})
},[])
  return (
    <>
    <Laboratorybanner/>
    <Laboratoryser/>
    </>
  )
}

export default Laboratory
