import { memo, useEffect } from "react"
import LazyLoad from "react-lazy-load"
import { Link } from "react-router-dom"

function LaboratoryTestingbann(){
    return (<>
        <LazyLoad>
            <div style={{height:"350px"}} className="banner laboratory" >
                <div style={{textAlign:"center",justifyContent:"center"}}>
                    <h3 style={{display:"flex",textAlign:"center",justifyContent:"center"}} className="mainbannertext">Ultrasound scans</h3>
                    <div className="bannertextf">
                        <Link to="/" className="bannertextf"><h6> Home </h6></Link>
                        <h6> / </h6>
                        <h6>Ultrasound scans</h6>
                    </div>
                </div>
            </div>
            </LazyLoad>
    </>)
}
export default memo(LaboratoryTestingbann)