import { memo } from "react"
import 'bootstrap/dist/css/bootstrap.css';
import "./Backfirstfix.css"
import { Link } from "react-router-dom"

function Backfirstfix(){
    return (<>
    <div className="backerfix mt-2 mb-2">
        <div className="container ">
            <div className="row textinbackfix">
                <h3>SPECTRA SCANS AND DIAGNOSTIC</h3>
                <p>We prioritize patient comfort and convenience, striving to create a warm and welcoming environment for all individuals. Our staff is dedicated to providing personalized care, addressing any concerns or questions you may have throughout the diagnostic process.</p>
                <a className="linkinboxfix"><h6>Contact Us</h6></a>
            </div>
        </div>
    </div>
    </>)
}
export default memo(Backfirstfix)