import { memo } from "react"
import LazyLoad from "react-lazy-load"

function ImagingServices(){
    return (<>
        <section className="mt-3">
               <div className="container">
                   <div className="row">
                       <h2>Digital x rays</h2>
                       <p>Digital X-rays, also known as digital radiography, are a modern imaging technology that has replaced traditional film-based X-rays in many medical and dental settings. Digital X-rays use electronic sensors to capture and produce digital images of the internal structures of the body. These images can then be viewed on a computer screen, stored electronically, and easily shared with other healthcare professionals.</p>
                   </div>
               </div>
           </section>
       <section className="mt-3 py-5 backgrey">
           <div className="container">
               <div className="centers">
                   <h5 style={{lineHeight:"28px"}}>Digital X-rays offer several benefits that make them helpful to patients in various ways</h5>
               </div>
               <div className="row">
                   <div className="col-xl-4 col-md-6 p-222">
                       <div className="row hiders p-1">
                           <div className="col-4 higerli">
                               <LazyLoad className="w-100">
                                   <img className="w-100" src="../photo/bb1.jpg" alt="" />
                               </LazyLoad>
                           </div>
                           <div className="col-8 p-1 linetext">
                               <h6>Faster Results</h6>
                               <p>Digital X-rays provide real-time images that can be viewed almost instantly on a computer screen.</p>
                           </div>
                       </div>
                   </div>
                   <div className="col-xl-4 col-md-6 p-222">
                       <div className="row hiders p-1">
                           <div className="col-4 higerli">
                               <LazyLoad className="w-100">
                                   <img className="w-100" src="../photo/bb2.jpg" alt="" />
                               </LazyLoad>
                           </div>
                           <div className="col-8 p-1 linetext">
                               <h6>Reduced Radiation Exposure</h6>
                               <p>Compared to traditional film X-rays, digital X-rays generally require less radiation to produce high-quality images.</p>
                           </div>
                       </div>
                   </div>
                   <div className="col-xl-4 col-md-6 p-222">
                       <div className="row hiders p-1">
                           <div className="col-4 higerli">
                               <LazyLoad className="w-100">
                                   <img className="w-100" src="../photo/bbbb3.jpg" alt="" />
                               </LazyLoad>
                           </div>
                           <div className="col-8 p-1 linetext">
                               <h6>Enhanced Image Quality</h6>
                               <p>Digital X-rays can be manipulated and enhanced using computer software, allowing healthcare professionals to adjust the brightness, contrast, and zoom level. </p>
                           </div>
                       </div>
                   </div>
                   <div className="col-xl-4 col-md-6 p-222">
                       <div className="row hiders p-1">
                           <div className="col-4 higerli">
                               <LazyLoad className="w-100">
                                   <img className="w-100" src="../photo/bb4.jpg" alt="" />
                               </LazyLoad>
                           </div>
                           <div className="col-8 p-1 linetext">
                               <h6>Electronic Storage and Accessibility</h6>
                               <p>Digital X-ray images are stored electronically in Picture Archiving and Communication Systems (PACS).</p>
                           </div>
                       </div>
                   </div>
                   <div className="col-xl-4 col-md-6 p-222">
                       <div className="row hiders p-1">
                           <div className="col-4 higerli">
                               <LazyLoad className="w-100">
                                   <img className="w-100" src="../photo/bb5.jpg" alt="" />
                               </LazyLoad>
                           </div>
                           <div className="col-8 p-1 linetext">
                               <h6>Reduced Environmental Impact</h6>
                               <p> Digital X-rays eliminate the need for film processing chemicals, which are harmful to the environment.</p>
                           </div>
                       </div>
                   </div>
                   <div className="col-xl-4 col-md-6 p-222">
                       <div className="row hiders p-1">
                           <div className="col-4 higerli">
                               <LazyLoad className="w-100">
                                   <img className="w-100" src="../photo/bb6.jpg" alt="" />
                               </LazyLoad>
                           </div>
                           <div className="col-8 p-1 linetext">
                               <h6>Improved Diagnosis</h6>
                               <p>The enhanced image quality and ability to manipulate digital X-rays allow healthcare professionals to spot even subtle abnormalities.</p>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </section>
    </>)
}
export default memo(ImagingServices)