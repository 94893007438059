import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Router, Routes, Route } from "react-router-dom";
import Home from "./component/Home/home";
import Header from "./component/Header/Header";
import About from "./component/AboutUs/About";
import Footer from "./component/Footer/footer";
import Contactbanner from "./component/Contact/Contactbanner";
import Contct from "./component/Contact/Contct";
import LaboratoryTesting from "./component/LaboratoryTesting/LaboratoryTesting";
import LaboratoryTestings from "./component/LaboratoryTesting/LaboratoryTestings";
import ImagingServices from "./component/LaboratoryTesting/ImagingServices";
import WellnessScreeningsss from "./component/LaboratoryTesting/WellnessScreeningsss";
import WellnessScreenings from "./component/LaboratoryTesting/WellnessScreenings";
import Mobbar from "./component/Mobbar/Mobbar";
import { useState } from "react";
import Fixheader from "./component/Header/Fixheader";
import Laboratory from "./component/Laboratory/Laboratory";
import Sonomammography from "./component/sonomammography/Sonomammography";

function App() {
  const [namcon, setNamcon] = useState("none");
  return (
    <>
      <BrowserRouter>
        <Mobbar namcon={namcon} setNamcon={setNamcon} />
        <Fixheader setNamcon={setNamcon} />
        <Header setNamcon={setNamcon} />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/service" element={<LaboratoryTesting />}>
            <Route
              path="/service/laboratoryTesting"
              element={<LaboratoryTestings />}
            ></Route>
            <Route
              path="/service/imagingServices"
              element={<ImagingServices />}
            ></Route>
            <Route
              path="/service/wellnessScreenings"
              element={<WellnessScreenings />}
            ></Route>
            <Route path="/service/laborator" element={<Laboratory />}></Route>
            <Route
              path="/service/sonomammography"
              element={<Sonomammography />}
            ></Route>
          </Route>
          <Route path="/contact" element={<Contct />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
