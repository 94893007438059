import { memo } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import 'bootstrap/dist/css/bootstrap.css';
import "./Homeinfo.css"
import { Link } from "react-router-dom"

function Homeinfo(){
    return (<>
    <div style={{marginBottom:"50px",marginTop:"70px"}}>
        <div style={{position:"relative"}} className="container">
            <div className="row bodiinfo">
                <div style={{display:"flex",alignItems:"center"}} className="col-xl-6 col-lg-6 flex justify-center item-center">
                    <div className="testinhom item-center">   
                    <h1>Spectra Scans and Diagnostic</h1>
                    <p>Welcome to our diagnostic center! We have latest technology dedicated to providing comprehensive and reliable diagnostic services. Our center is committed to delivering accurate results and exceptional patient care, ensuring that you receive the highest standard of medical expertise and service.</p>
                    <p>At our diagnostic center, we understand the importance of early detection and accurate diagnoses in healthcare. That's why we have invested in latest technology and equipment to offer a wide range of diagnostic tests and procedures. Our team of experienced and highly skilled medical professionals includes doctors, technicians, and support staff who work together to deliver timely and precise results.</p>
                    <Link className="infoapper">
                                <h6>Appointment</h6>
                    </Link>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <LazyLoadImage className="w-100" src="photo/hospitals.jpg" alt="" />
                </div>
            </div>
        </div>
    </div>
    </>)
}
export default memo(Homeinfo)