import { memo } from "react"
import "./Whychooseus.css"
import { Link } from "react-router-dom"

function Whychooseus(){
    return (<>
    <section className="my-5">
        <div className="container">
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                    <div className="linerstexew">   
                    <h1>Why choose us</h1>
                    <p>We prioritize patient comfort and convenience, striving to create a warm and welcoming environment for all individuals. Our staff is dedicated to providing personalized care, addressing any concerns or questions you may have throughout the diagnostic process.</p>
                    <p>As a diagnostics center, we maintain strict adherence to ethical standards, patient confidentiality, and regulatory guidelines. Your privacy and data security are of utmost importance to us.</p>
                    <p>We look forward to serving you and assisting you in your healthcare journey. Our goal is to provide accurate diagnoses, facilitate effective treatments, and ultimately contribute to your overall well-being.</p>
                    <ul className="footerul listnone">
                            <li className="mb-2">
                                <div className="homecall textimg footertext">
                                    <img src="../photo/placeholder.png" alt="" />
                                <h6 style={{color:"black"}}>No 118/6B,Ground floor, LPS Complex, Haralur Main Road, opposite Anjaneya temple, PWD Quarters, 1st Sector, HSR Layout, Bengaluru, Karnataka 560102</h6>
                                </div>
                            </li>
                            <li>
                                <div className="homecall textimg footertext pt-2">
                                    <img src="../photo/telephone.png" alt="" />
                                    <h6 style={{color:"black"}}>081231 96290</h6>
                                </div>
                            </li>
                            <li>
                                <div className="homecall textimg footertext pt-2">
                                    <img src="../photo/email.png" alt="" />
                                    <h6 style={{color:"black"}}>spectrascans@gmail.com</h6>
                                </div>
                            </li>
                            <li>
                                <div className="homecall textimg footertext pt-2">
                                    <img src="../photo/clock.png" alt="" />
                                    <div>
                                        
                                <h6 style={{color:"black"}}>Mon - Sat : 08:30 am to 08:30 PM</h6>
                                <h6 style={{color:"black"}}>Sun-Holidays : 08:30 am to 01:00 PM</h6>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-6 centifier">
                <from className="serverssss">  
                            <input className="inputers" type="text" placeholder="   Name"/>
                            <input className="inputers" type="text" placeholder="   Phone"/>
                            <textarea className="inputerss" type="text"  placeholder="   Message"/>
                            <div className="subber">
                                <button className="submiter">Submit</button>
                            </div>
                        </from>
                </div>
            </div>
        </div>
    </section>
    </>)
}
export default memo(Whychooseus)