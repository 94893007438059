import React, { useEffect } from 'react'
import Sonomammographybanner from './Sonomammographybanner'
import Sonomammographyser from './Sonomammographyser'

function Sonomammography() {
  useEffect(()=>{
    window.scrollTo({top:"0px",behavior:"smooth"})
},[])
  return (<>
  <Sonomammographybanner/>
  <Sonomammographyser/>
  </>
  )
}

export default Sonomammography
