import { memo } from "react"
import "./Useofscan.css"

function Useofscan(){
    return (<>
    <section className="containe">
        <div className="containe">
            <div className="containe">
                <div className="col-lg-4 biggersmall">
                    <div className="numberslink">
                        
                <h4>Importance Of Scans and Diagnostic</h4>
                <h6>
Scans and diagnostics play a crucial role in various fields, including medicine, technology, and research. Here are some of the key advantages of utilizing scans and diagnostics.</h6>
                <button className="bookappoint">Book Appointment</button>
                    </div>
            </div>
        <div className="col-lg-8 py-5 bgvision">
            <div className="containe">
                
        <div className="col-lg-6 bgvision pya-5">
            <div className=" p-3 innnerh1">
                <h1>01</h1>
                <h5>Early detection and diagnosis</h5>
                <p>Scans and diagnostics can identify health conditions, diseases, and issues at an early stage, even before noticeable symptoms appear. Early detection allows for timely intervention and treatment, leading to better outcomes and improved chances of recovery.</p>
            </div>
        </div>
        <div className="col-lg-6 bgvision pya-5">
            <div className="p-3 innnerh1">
                <h1>02</h1>
                <h5>Non-invasive or minimally invasive</h5>
                <p>Many scanning and diagnostic procedures are non-invasive or minimally invasive, reducing patient discomfort and the risk of complications associated with more invasive methods. This makes them safer and more tolerable for patients.</p>
            </div>
        </div>
        <div className="col-lg-6 bgvision pya-5">
            <div className="p-3 innnerh1">
                <h1>03</h1>
                <h5>Objective and accurate results</h5>
                <p>Scans and diagnostics provide objective data, reducing the potential for human error in interpretation. This accuracy helps healthcare professionals make well-informed decisions about patient care and treatment plans.</p>
            </div>
        </div>
        <div className="col-lg-6 bgvision pya-5">
            <div className="p-3 innnerh1">
                <h1>04</h1>
                <h5>Monitoring disease progression</h5>
                <p>Scans and diagnostics are valuable for monitoring the progression of diseases and conditions over time. By tracking changes in the body or specific parameters, healthcare providers can adjust treatment strategies accordingly.</p>
            </div>
        </div>
            </div>
        </div>
            </div>
        </div>
    </section>
    </>)
}
export default memo(Useofscan)