import { memo, useEffect } from "react"
import "./Mobbar.css"
import { Link } from "react-router-dom"

function Mobbar({namcon, setNamcon}){
    useEffect(()=>{
        window.addEventListener('resize',()=>{
            setNamcon("none")
        })
        return (()=>{
            window.removeEventListener('resize',()=>{
                
            setNamcon("none")
            })
        })
    })
    const change={
        display:`${namcon}`
    }
    return (<>
    <section style={change} className="posimob">
        <div className="namesmob p-2">
        <div className="logobox">
            <img src="../photo/logo.png" alt="" />
        </div>
        <div onClick={()=>{
            setNamcon("none")
        }} className="widthcan">
            <img src="../photo/cancel.png" alt="" />
        </div>
        </div>
        <div className="backmob p-2 pb-4">
                        <ul className="footerul listnone">
                            <li>
                                <Link to="/" onClick={()=>{
            setNamcon("none")
        }} className="foseli chancemob">
                                <div className="homecall textimg footertext p-2 chancemob">
                                    <img src="../photo/next.png" alt="" />
                                    <h6>Home</h6>
                                </div>
                                </Link>
                            </li>
                            <li>
                                <Link onClick={()=>{
            setNamcon("none")
        }} to="/about" className="foseli chancemob">
                                <div className="homecall textimg footertext p-2  chancemob" >
                                    <img src="../photo/next.png" alt="" />
                                    <h6>About Us</h6>
                                </div>
                                </Link>
                            </li>
                           
                            <li>
                                <Link to="#" className="foseli chancemob">
                                <div className="homecall textimg footertext p-2  chancemob">
                                    <img src="../photo/next.png" alt="" />
                                    <h6>Services</h6>
                                </div>
                                </Link>
                                <ul className="linerline">
                                <li>
                                <Link onClick={()=>{
            setNamcon("none")
        }} to="/service/laboratoryTesting" className="foseli chancemob">
                                <div className="homecall textimg footertext p-2  chancemob">
                                    <img src="../photo/next.png" alt="" />
                                    <h6>Ultrasound scans</h6>
                                </div>
                                </Link>
                            </li><li>
                                <Link onClick={()=>{
            setNamcon("none")
        }} to="/service/imagingServices" className="foseli chancemob">
                                <div className="homecall textimg footertext p-2 chancemob">
                                    <img src="../photo/next.png" alt="" />
                                    <h6>Digital x rays</h6>
                                </div>
                                </Link>
                            </li><li>
                                <Link onClick={()=>{
            setNamcon("none")
        }} to="/service/wellnessScreenings" className="foseli chancemob">
                                <div className="homecall textimg footertext p-2  chancemob">
                                    <img src="../photo/next.png" alt="" />
                                    <h6>Hysterosalpingography</h6>
                                </div>
                                </Link>
                                <Link onClick={()=>{
            setNamcon("none")
        }} to="/service/laborator" className="foseli chancemob">
                                <div className="homecall textimg footertext p-2  chancemob">
                                    <img src="../photo/next.png" alt="" />
                                    <h6>Laboratory/Blood tests</h6>
                                </div>
                                </Link>
                                <Link onClick={()=>{
            setNamcon("none")
        }} to="/service/sonomammography" className="foseli chancemob">
                                <div className="homecall textimg footertext p-2  chancemob">
                                    <img src="../photo/next.png" alt="" />
                                    <h6>Sonomammography</h6>
                                </div>
                                </Link>
                            </li>
                                </ul>
                            </li>
                            <li>
                                <Link onClick={()=>{
            setNamcon("none")
        }} to="/contact" className="foseli chancemob">
            
            
                                <div className="homecall textimg footertext p-2 chancemob">
                                    <img src="../photo/next.png" alt="" />
                                    <h6>Contact Us</h6>
                                </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
    </section>
    </>)
}
export default memo(Mobbar)