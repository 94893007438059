import { memo } from "react"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./footer.css"
import { Link } from "react-router-dom"
import LazyLoad from "react-lazy-load"

function footer(){
    return (<>
    <footer>
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-6 mb-2">
                <div className="w-100">
                    <div className="footerlogo">
                        <img src="../photo/logo.png" alt="" />
                    </div>
                </div>
                <div className="pt-4 textfooter">
                    <h6>Welcome to our diagnostics center! We are a state-of-the-art facility dedicated to providing comprehensive and reliable diagnostic services. Our center is committed to delivering accurate results and exceptional patient care, ensuring that you receive the highest standard of medical expertise and service.</h6>
                </div>
                <div className="w-100 jucticenter pt-4">
                    <div className="display-flex">
                        <a className="homecall textimg paddimg firsticon" href="">  
                            <img src="../photo/facebook-logo.png" alt="" />
                        </a>
                        <a className="homecall textimg paddimg firsticon" href="">  
                            <img src="../photo/google.png" alt="" />
                        </a>
                        <a className="homecall textimg paddimg firsticon" href="">  
                            <img src="../photo/twitter.png" alt="" />
                        </a>
                        <a className="homecall textimg paddimg firsticon" href="">  
                            <img src="../photo/instagram.png" alt="" />
                        </a>
                    </div>
                </div>
            </div>
                <div className="col-lg-3 col-md-6 mb-2">
                    <div className="secexplotitt">
                        <h3>Explore</h3>
                    </div>
                    <div>
                        <ul className="footerul">
                            <li>
                                <Link to="/" className="foseli"><h6>Home</h6></Link>
                            </li>
                            <li>
                                <Link to="/about" className="foseli"><h6>About Us</h6></Link>
                            </li>
                            <li>
                                <Link to="/service/laboratoryTesting" className="foseli"><h6>Services</h6></Link>
                            </li>
                            <li>
                                <Link to="/contact" className="foseli"><h6>Contact</h6></Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-2">
                    <div className="secexplotitt">
                        <h3>Services</h3>
                    </div>
                    <div>
                        <ul className="footerul listnone">
                            <li>
                            <Link to="/service/laboratoryTesting" className="row hiders p-1 changelink">
                           <div className="col-4 higerli">
                               <LazyLoad className="w-100">
                                   <img className="w-100" src="../photo/ww1.jpg" alt="" />
                               </LazyLoad>
                           </div>
                           <div style={{color:"white"}} className="col-8 p-1 linetext">
                               <h6>Ultrasound scans</h6>
                               <p>Ultrasound scans, also known as sonography...</p>
                           </div>
                       </Link>
                            </li>
                            <li>
                            <Link to="/service/imagingServices" className="row hiders p-1 changelink">
                           <div className="col-4 higerli">
                               <LazyLoad className="w-100">
                                   <img className="w-100" src="../photo/bb5.jpg" alt="" />
                               </LazyLoad>
                           </div>
                           <div style={{color:"white"}} className="col-8 p-1 linetext">
                               <h6>Digital x rays</h6>
                               <p>Digital X-rays, also known as digital radiography... </p>
                           </div>
                       </Link>
                            </li>
                           
                            <li>
                            <Link to="/service/wellnessScreenings" className="row hiders p-1 changelink">
                           <div className="col-4 higerli">
                               <LazyLoad className="w-100">
                                   <img className="w-100" src="../photo/bb1.jpg" alt="" />
                               </LazyLoad>
                           </div>
                           <div style={{color:"white"}} className="col-8 p-1 linetext">
                               <h6>Hysterosalpingography</h6>
                               <p>Hysterosalpingography (HSG) is a medical imaging...</p>
                           </div>
                       </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-2">
                    <div className="secexplotitt">
                        <h3>Contact info</h3>
                    </div>
                    <div>
                        <ul className="footerul listnone">
                            <li className="mb-2">
                                <div className="homecall textimg footertext">
                                    <img src="../photo/placeholder.png" alt="" />
                                    <h6>Our Office Address</h6>
                                </div>
                                <h6>No 118/6B,Ground floor, LPS Complex, Haralur Main Road, opposite Anjaneya temple, PWD Quarters, 1st Sector, HSR Layout, Bengaluru, Karnataka 560102</h6>
                            </li>
                            <li>
                                <div className="homecall textimg footertext pt-2">
                                    <img src="../photo/telephone.png" alt="" />
                                    <h6>081231 96290</h6>
                                </div>
                            </li>
                            <li>
                                <div className="homecall textimg footertext pt-2">
                                    <img src="../photo/email.png" alt="" />
                                    <h6>spectrascans@gmail.com</h6>
                                </div>
                            </li>
                            <li>
                                <div className="homecall textimg footertext pt-2">
                                    <img src="../photo/clock.png" alt="" />
                                    <h6>Our Timing</h6>
                                </div>
                                <h6>Mon - Sat : 08:30 am to 08:30 PM</h6>
                                <h6>Sun-Holidays : 08:30 am to 01:00 PM</h6>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row pt-3">
                <a href="" className="footalik footertext col-12 ">
                                <div className="homecall textimg footerdisnone">
                                    <img src="../photo/copyright.png" alt="" />
                                    <h6>Spectra Scans and Diagnostics, Design By</h6>
                                </div>
                                <h6>appaddindia.com</h6>
                </a>
            </div>
        </div>
    </footer>
    </>)
}
export default memo(footer)